import { dataRegex } from './formatting.data.js';
import { format as formatDate,parse as dateParse } from 'date-fns';
import { getUserSession } from 'o365-modules';

/**
 * Gets date string separator
 * @param {string} pDate - dat string.
 */
const getDateSeparator = (pDate) => {
    if (pDate.indexOf(".") > -1) return ".";
    if (pDate.indexOf("/") > -1) return "/";
    return "-";
};

/**
 * Adds leading zeros to date string. 2022-1-1 => 2022-01-01
 * @param {string} pDate - dat string.
 */
const addLeadingZeros = (pDate) => {
    if (pDate) {
        var vSeparator = getDateSeparator(pDate.split(" ")[0]);
        var vSplitted = pDate.split(vSeparator),
            vParts = [];
        vSplitted.forEach(function (item) {
            if (String(item).length === 1) {
                vParts.push("0" + item);
            } else {
                vParts.push(item);
            }
        });
        return vParts.join(vSeparator);
    }
    return pDate;
};


/**
 * check if given string might be ISO date
 * @param {string} pDate - date string.
 */
const tryCheckDate = (pDate) => {
    if (!/\d{4}-\d{2}-\d{2}/.test(pDate)) return false;
    let d = new Date(pDate);
    if (d.toISOString() === pDate + "T00:00:00.000Z") return true;
    let vDate = new Date(addLeadingZeros(pDate));
    if (vDate && vDate.toDateString() !== 'Invalid Date') {
        return true;
    }
    return false;
};
/**
 * check if given string might need leading zeros
 * @param {string} pDate - date string.
 */
const tryCheckDateLeadingZeros = (pDate) => {
    let vDate = new Date(addLeadingZeros(pDate));
    if (vDate && vDate.toDateString() !== 'Invalid Date') {
        return true;
    }
    //return af.common.unFormatDate(af.common.addLeadingZerosToDate(pDate));
    return false;
};
/**
 * Try to parse date with all available methods
 * @param {string} pDate - date string.
 */
const parseDate = (pDate) => {
    if (!pDate) return null;
    if(dataRegex.isodate.test(pDate)){
         return new Date(pDate);
    }
    switch (pDate) {
        case "":
            return null;
        case /^\d{4}-\d{2}-\d{2}[ T]\d{2}:\d{2}/.test(pDate) && pDate:
            return new Date(pDate);
        case /^\d{4}-\d{2}-\d{2}[ T]\d{2}:\d{2}:\d{2}/.test(pDate) && pDate:
            return new Date(pDate);
        default:
            if(tryCheckDate(pDate)){
                return new Date(pDate);
            }
            if(tryCheckDateLeadingZeros(pDate)){
                return new Date(addLeadingZeros(pDate));
            }

            return null;
    }
};

const getFormat = (pFormat) => {
    if (!pFormat) {
        pFormat = getUserSession().formats["General Date Short Time"];
    }
    if (pFormat && getUserSession().formats && getUserSession().formats[pFormat]) {
        pFormat = getUserSession().formats[pFormat];
    }

    if(!getUserSession().formats) return 'dd/MM/yyyy';

    // Replace depricated patterns
    pFormat = pFormat.replace('dddd', 'iiii')

    return pFormat;
}

const format = (pDate, pFormat, utc) => {
    pFormat = getFormat(pFormat);
     if(!pFormat){
        pFormat = 'dd/MM/yyyy';
    }
    if (utc) {
        const isoDate = pDate?.toISOString ? pDate.toISOString() : new Date(pDate).toISOString();
        pDate = new Date(`${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`);
    }
    // return formatDate(pDate, pFormat, { locale: nb });
    return formatDate(pDate, pFormat);
}

const unFormat = (pDateString, pFormat)=>{
    if (!pFormat) {
        pFormat = getUserSession().formats["General Date Short Time"];
    }
    if (pFormat && getUserSession().formats[pFormat]) {
        pFormat = getUserSession().formats[pFormat];
    }
    return dateParse(pDateString,pFormat,new Date());
}



export default { getDateSeparator, addLeadingZeros, parseDate, format, getFormat,dateParse,unFormat };


